import { ReactNode } from 'react'
import { Container, Wrapper } from './styles'

interface SwiperItemProps {
  backgroundImage?: string
  children: ReactNode
}

export const SwiperItem: React.FC<SwiperItemProps> = ({
  children,
  backgroundImage
}: SwiperItemProps) => {
  return (
    <Container style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Wrapper>{children}</Wrapper>
    </Container>
  )
}
