import styled, { css } from 'styled-components'

export const Hero = styled.section`
  width: 100%;
  height: content;
  padding: 10rem 0;
  background-size: cover;
  padding-top: 16.5rem;
  display: flex;
  justify-content: center;
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
  `}
`
