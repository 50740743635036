import { createAsyncThunk } from "@reduxjs/toolkit";
import { EngageState, AppDispatch } from "../DomainLayer";
import { PetInfo } from "../entities/Pet";
import {
  getAllPets,
  getPet,
  createPet,
  updatePet,
  deletePet,
} from "../../data/services/pet";

export interface ThunkApi {
  dispatch: AppDispatch;
  state: EngageState;
  rejectValue: string;
}

export const PetActions = {
  GET_ALL_PETS: "thunk/pet/getAllPetsThunk",
  GET_PET: "thunk/pet/getPetThunk",
  CREATE_PET: "thunk/pet/createPetThunk",
  UPDATE_PET: "thunk/pet/updatePetThunk",
  DELETE_PET: "thunk/pet/createPetThunk",
};

export const getAllPetsThunk = createAsyncThunk<PetInfo[], void, ThunkApi>(
  PetActions.GET_ALL_PETS,
  async (payload, thunkAPI) => {
    try {
      const pets = await getAllPets();

      return pets;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getPetThunk = createAsyncThunk<PetInfo, number, ThunkApi>(
  PetActions.GET_PET,
  async (id, thunkAPI) => {
    try {
      const pet = await getPet(id);

      return pet;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createPetThunk = createAsyncThunk<void, PetInfo, ThunkApi>(
  PetActions.CREATE_PET,
  async (newPet, thunkAPI) => {
    try {
      await createPet(newPet);
      thunkAPI.dispatch(getAllPetsThunk());
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updatePetThunk = createAsyncThunk<void, PetInfo, ThunkApi>(
  PetActions.UPDATE_PET,
  async (payload, thunkAPI) => {
    try {
      await updatePet(payload);
      thunkAPI.dispatch(getAllPetsThunk());
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deletePetThunk = createAsyncThunk<void, number, ThunkApi>(
  PetActions.DELETE_PET,
  async (id, thunkAPI) => {
    try {
      await deletePet(id);
      thunkAPI.dispatch(getAllPetsThunk());
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
