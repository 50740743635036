import styled, { css } from "styled-components";

export const CarouselContainer = styled.div`
  padding-top: 19rem;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  width: 77.7rem;
  display: flex;
`;

export const ParagraphWrapper = styled.div`
  width: 66rem;
  display: flex;
  padding-top: 3.2rem;
`;
export const CarouselTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color200};
    font-family: ${theme.fonts.standard};
    display: inline;
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
  `}
`;

export const CarouselParagraph = styled.p`
  ${({ theme }) => css`
    margin-top: 3rem;
    margin-bottom: 9.6rem;
    width: 66rem;
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.lato};
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.6rem;
  `}
`;

export const TitleColored = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
  `}
`;

export const BannerTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
  `}
`;

export const BannerParagraph = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.lato};
    font-size: 2.8rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 12.4rem;
    width: 79.9rem;
  `}
`;
