import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    width: 24rem;
    height: 32.9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.9rem;
    > img {
      width: 100%;
      height: 30.9rem;
      object-fit: cover;
      border-radius: 1rem;
    }
    > button {
      width: 100%;
    }
  `}
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.5rem;
`;

export const TextContainer = styled.div`
  ${({ theme }) => css`
    gap: 1.3rem;
    margin-left: 0.5rem;
    > p {
      color: ${theme.colors.hex.white};
      font-family: ${theme.fonts.standard};
      font-size: 2.8rem;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
    }
    > span {
      color: ${theme.colors.hex.white};
      font-family: ${theme.fonts.standard};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  `}
`;

export const GenderImgContainer = styled.div`
  margin-right: 1.5rem;
  width: 1.65rem;
  height: 2.47rem;
`;
