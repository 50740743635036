import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.hex.black.color900};
    padding-bottom: 15.4rem;
    padding-top: 13rem;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  `}
`;

export const TitleTextContainer = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standard};
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 3.0rem
    text-align: center;
  `}
`;

export const SubTitleTextContainer = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standard};
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5.8rem;
  `}
`;

export const StyledSwiper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 1rem;

    .swiper-pagination {
      position: relative;
      width: 100%;
      bottom: -11.8rem;
      margin-bottom: 15.4rem;
    }
    .swiper-pagination-bullet {
      background-color: ${theme.colors.hex.primary.color600};
      width: 2rem;
      height: 2rem;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`;
