import React, { useState } from "react";
import { Container, NavContainer, Wrapper } from "./styles";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { Button } from "../../atoms/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../Router";
import { Modal } from "../../organisms/Modal";

export const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Wrapper>
        <Link to={ROUTES.ROOT}>
          <Logo />
        </Link>

        <NavContainer>
          <Link to={ROUTES.ROOT}>
            <Button variant="text">Home</Button>
          </Link>

          <Link to={ROUTES.INSTITUTIONAL}>
            <Button variant="text">Institucional</Button>
          </Link>

          <Link to={ROUTES.ADOPTION}>
            <Button variant="text">Adoção</Button>
          </Link>

          <Link to={ROUTES.CONSTRUCTION}>
            <Button variant="text">Blog</Button>
          </Link>

          <Button variant="contained" onClick={handleOpenModal}>
            Doar
          </Button>

          <Link to={ROUTES.CONSTRUCTION}>
            <Button variant="contained" color="secondary">
              Login
            </Button>
          </Link>
        </NavContainer>
      </Wrapper>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        isQRCodeModal={true}
      />
    </Container>
  );
};
