import { Dispatch } from "redux";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import { UserInfo } from "../entities/auth";
import { postUser } from "../../data/services/auth";

export interface ThunkApi {
  dispatch: Dispatch;
  state: EngageState;
  rejectValue: string;
}

export const userActions = {
  POST_USER: "thunk/auth/postUserThunk",
};

export const postUserThunk = createAsyncThunk<UserInfo, UserInfo, ThunkApi>(
  userActions.POST_USER,
  async (payload, thunkAPI) => {
    try {
      const user = await postUser(payload);
      return user;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
