import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  ${({ theme }) => css`
    background: ${theme.colors.hex.black.color900};
    color: ${theme.colors.hex.primary.color600};
    font-family: ${theme.fonts.inter};
    padding-top: 10rem;
    padding-bottom: 4.3rem;
    display: flex;
    justify-content: center;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 14.9rem;
  `}
`;

export const BoxFlexColumn = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 6.5rem;
`;

export const StyledLogo = styled(Link)`
  flex: 1;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 37.5rem;
`;

export const Subtitle = styled.p`
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinkEmail = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    text-decoration: none;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `}
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    text-decoration: none;
    cursor: pointer;
    font-size: 2.4rem;
    font-weight: 400;
  `}
`;

export const StyledLinkButton = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    text-decoration: none;
    cursor: pointer;
    font-size: 2.4rem;
    font-weight: 400;
  `}
`;

export const CopyrightMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color900};
    font-family: ${theme.fonts.inter};
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `}
`;

export const SocialIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5rem;
`;
