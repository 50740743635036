import React, { AllHTMLAttributes, useState } from "react";
import CatAndGirl from "../../../assets/img/CatAndGirl.svg";
import Vonluntaries from "../../../assets/img/Vonluntaries.svg";
import dogWayToHelp from "../../../assets/img/dogWayToHelp.svg";
import { Container, Wrapper, TitleWayToHelp, DivWaytoHelp } from "./styles";
import { Card } from "../../molecules/Card/index";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../Router";
import { Modal } from "../Modal";

type ContainerHelpProps = AllHTMLAttributes<HTMLDivElement>;

export const ContainerHelp: React.FC<ContainerHelpProps> = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Container>
      <Wrapper>
        <TitleWayToHelp>Maneiras de Ajudar</TitleWayToHelp>
        <DivWaytoHelp>
          <Card
            title="Adoção"
            altText="Adoção"
            description="Encontre seu novo melhor amigo! Adote um filhote e transforme vidas hoje mesmo."
            imageSrc={CatAndGirl}
            buttonText="ADOTE"
            onButtonClick={() => handleNavigate(ROUTES.ADOPTION)}
            key={1}
          />
          <Card
            title="Faça uma Doação"
            altText="Faça uma Doação"
            description="Contribua com qualquer valor e ajude a manter a SOLPRA manter e preservar a vida animal."
            imageSrc={Vonluntaries}
            buttonText="QUERO DOAR"
            onButtonClick={handleOpenModal}
            key={2}
          />
          <Card
            title="Achados e Perdidos"
            altText="Achados e Perdidos"
            description="Em caso de catástrofes ou mesmo de aparecimento do seu filho a SOLPRA estará aqui para ajudar a encontrá-lo."
            imageSrc={dogWayToHelp}
            buttonText="ENCONTRE SEU PET"
            onButtonClick={() => handleNavigate(ROUTES.ADOPTION)}
            key={3}
          />
        </DivWaytoHelp>
      </Wrapper>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        isQRCodeModal={true}
      />
    </Container>
  );
};
