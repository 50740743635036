import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowUp } from "../../../assets/img/arrowup.svg";
import { ScrollButtonWrapper } from "./styles";

const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const ScrollButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return isVisible ? (
    <ScrollButtonWrapper>
      <ArrowUp onClick={ScrollToTop} />
    </ScrollButtonWrapper>
  ) : null;
};
