import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { petSelector } from "../../../../domain/ducks/petReducer";
import { AppDispatch } from "../../../../domain/DomainLayer";
import { getAllPetsThunk } from "../../../../domain/thunks/petThunk";
import { SwiperSlide } from "swiper/react";
import { GalleryCard } from "../../molecules/GalleryCard";
import { Carousel } from "../Carousel";
import { Grid } from "swiper/modules";
import {
  Container,
  TitleTextContainer,
  SubTitleTextContainer,
  Wrapper,
  StyledSwiper,
} from "./styles";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const Gallery: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { pets } = useSelector(petSelector);

  useEffect(() => {
    dispatch(getAllPetsThunk());
  }, []);

  return (
    <>
      <Container>
        <Wrapper>
          <TitleTextContainer>Galeria dos Pets</TitleTextContainer>
          <SubTitleTextContainer>
            Confira a galeria de pets cadastrados:
          </SubTitleTextContainer>

          {pets.length > 0 ? (
            <StyledSwiper>
              <Carousel
                showNavigation={false}
                slidesPerView={3}
                spaceBetween={100}
                modules={[Grid]}
                grid={{
                  rows: 2,
                  fill: "row",
                }}
              >
                {pets.map((pet, index) => (
                  <SwiperSlide key={index}>
                    <GalleryCard petInfo={pet} />
                  </SwiperSlide>
                ))}
              </Carousel>
            </StyledSwiper>
          ) : (
            <SubTitleTextContainer>
              Nenhum pet cadastrado ainda.
            </SubTitleTextContainer>
          )}
        </Wrapper>
      </Container>
    </>
  );
};
