import ReactDOM from "react-dom/client";
import { initializePresentationLayer } from "./presentation/PresentationLayer";
import reportWebVitals from "./reportWebVitals";
import { store } from "./domain/DomainLayer";
import { initializeDataLayer } from "./data/DataLayer";

initializeDataLayer();
const App = initializePresentationLayer(store);

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container as Element);
root.render(<App />);

reportWebVitals();
