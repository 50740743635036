import ReactDOM from "react-dom";
import React, { ReactNode } from "react";

import {
  ModalBody,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  QRCodeModalHeader,
  QRCodeModalBody,
  QRCodeModalFooter,
} from "./styles";
import { QRCodeCanvas } from "qrcode.react";
import { Button } from "../../atoms/Button";
import { toast } from "react-toastify";

interface ModalProps {
  headerRender?: ReactNode;
  bodyRender?: ReactNode;
  footerRender?: ReactNode;
  open?: boolean;
  onClose: () => void;
  onButtonClick?: () => void;
  isQRCodeModal?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  headerRender,
  bodyRender,
  footerRender,
  open,
  onClose,
  isQRCodeModal,
}) => {
  if (!open) return null;

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const QRCodeValue =
    "00020126360014BR.GOV.BCB.PIX0114044474330001405204000053039865802BR5906SOLPRA6006Laguna62070503***630475C6";

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(QRCodeValue)
      .then(() => {
        toast.success("Código PIX copiado com sucesso!");
      })
      .catch((err) => {
        console.log("Falha ao copiar o código: " + err);
      });
  };

  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleClose}>
      <ModalContainer>
        <ModalHeader>
          {isQRCodeModal ? (
            <QRCodeModalHeader>Faça uma doação</QRCodeModalHeader>
          ) : (
            headerRender
          )}
        </ModalHeader>
        <ModalBody>
          {isQRCodeModal ? (
            <QRCodeModalBody>
              <QRCodeCanvas size={320} value={QRCodeValue} />
            </QRCodeModalBody>
          ) : (
            bodyRender
          )}
        </ModalBody>
        <ModalFooter>
          {isQRCodeModal ? (
            <QRCodeModalFooter>
              <Button variant="contained" onClick={handleCopyToClipboard}>
                Ou copie o Código PIX
              </Button>
            </QRCodeModalFooter>
          ) : (
            footerRender
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>,
    document.body
  );
};
