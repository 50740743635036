import {
  configureStore,
  EnhancedStore,
  combineReducers,
} from "@reduxjs/toolkit";
import { ReducersMapObject } from "redux";
import { PetActionType, PetState, petReducer } from "./ducks/petReducer";
import { AuthState, authReducer } from "./ducks/authReducer";

export interface EngageState {
  readonly pet: PetState;
  readonly auth: AuthState;
}

export type EngageActions = PetActionType;

export type EngageStore = EnhancedStore<EngageState, EngageActions>;

const rootReducer: ReducersMapObject<EngageState, EngageActions> = {
  pet: petReducer,
  auth: authReducer,
};

const combinedReducers = combineReducers(rootReducer);

const appReducer = (state: any, action: any) => combinedReducers(state, action);

export const store = configureStore({
  reducer: appReducer,
});

export type AppDispatch = typeof store.dispatch;
