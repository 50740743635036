import React, { useRef } from "react";
import { ButtonContainer, Container, StyledSwiper } from "./styles";
import { Swiper, SwiperProps } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/pagination";
import { ReactComponent as NavIconLeft } from "../../../assets/img/swiper-navicon-left.svg";
import { ReactComponent as NavIconRight } from "../../../assets/img/swiper-navicon-right.svg";

interface CarouselProps extends SwiperProps {
  showNavigation?: boolean;
  color?: string;
}

export const Carousel: React.FC<CarouselProps> = ({
  children,
  showNavigation = true,
  color,
  spaceBetween = 100,
  modules = [],
  loop,
  ...rest
}) => {
  const swiperRef = useRef<SwiperType | null>(null);

  return (
    <Container data-color={color}>
      <StyledSwiper>
        <Swiper
          onBeforeInit={(swiper: SwiperType) => {
            swiperRef.current = swiper;
          }}
          modules={[...modules, Pagination, Navigation]}
          spaceBetween={spaceBetween}
          pagination={{
            clickable: true,
          }}
          loop={rest.grid ? false : loop}
          {...rest}
        >
          {children}
          {showNavigation && (
            <ButtonContainer>
              <button onClick={() => swiperRef.current?.slidePrev()}>
                <NavIconLeft />
              </button>
              <button onClick={() => swiperRef.current?.slideNext()}>
                <NavIconRight />
              </button>
            </ButtonContainer>
          )}
        </Swiper>
      </StyledSwiper>
    </Container>
  );
};
