import { ButtonHTMLAttributes, ReactNode } from "react";
import { StyledButton } from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: string;
  color?: string;
  children?: ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  variant,
  color,
  children,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton {...props} data-variant={variant} data-color={color}>
      {children}
    </StyledButton>
  );
};
