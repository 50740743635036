import styled, { css } from "styled-components";

export const WrapperContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const CarouselContainer = styled.div`
  padding-top: 19rem;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const TitleWrapper = styled.div`
  width: 63.2rem;
  display: flex;
  justify-content: flex-end;
`;

export const ParagraphWrapper = styled.div`
  width: 55.9rem;
  display: flex;
  padding-top: 3rem;
`;

export const LinkWrapper = styled.div`
  width: 55.9rem;
  display: flex;
  padding-top: 5.8rem;
`;

export const CarouselTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color200};
    font-family: "Mulish", sans-serif;
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
    width: 63.2rem;
  `}
`;

export const CarouselParagraph = styled.p`
  ${({ theme }) => css`
    margin-top: 3rem;
    margin-bottom: 5.8rem;
    color: ${theme.colors.hex.gray.color200};
    font-family: "Mulish", sans-serif;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.6rem;
  `}
`;

export const DivParagraph = styled.div`
  width: 55.9rem;
`;
