import styled, { css } from "styled-components";

export const StyledButton = styled.button`
  ${({ theme }) => css`
    font-size: 1.2rem;
    padding: 1.2rem 1.5rem;
    border: none;
    outline: none;
    border-radius: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-family: ${theme.fonts.standard};

    &[data-variant="contained"] {
      background-color: ${theme.colors.hex.primary.color600};
      color: ${theme.colors.hex.white};
      font-weight: 800;

      &[data-color="secondary"] {
        background-color: ${theme.colors.hex.white};
        color: ${theme.colors.hex.black.color900};
      }

      &[data-color="banner"] {
        background-color: ${theme.colors.hex.black.color900};
        color: ${theme.colors.hex.white};
      }
    }

    &[data-variant="EditAdmButton"] {
      color: ${theme.colors.hex.black.color900};
      font-size: 1.4rem;
      background: none;
      text-transform: none;
      padding: 0.6rem 1.5rem;
      border-radius: 0.6rem;
      border: 0.1rem solid ${theme.colors.hex.black.color300};

      &:hover {
        color: ${theme.colors.hex.primary.color400};
        border: 0.1rem solid ${theme.colors.hex.primary.color400};
      }
    }
    &[data-variant="helpsavebutton"] {
      background-color: ${theme.colors.hex.primary.color600};
      color: ${theme.colors.hex.white} !important;
      border-color: ${theme.colors.hex.white};
      font-weight: 800;
      padding: 0;
      width: 40rem;
      height: 3rem;
    }

    &[data-variant="helpclosebutton"] {
      background-color: ${theme.colors.hex.white};
      color: ${theme.colors.hex.black} !important;
      font-weight: 800;
      padding: 0;
      width: 38rem;
      height: 3rem;
    }

    &[data-variant="text"] {
      background-color: transparent;
      color: ${theme.colors.hex.white};
      font-weight: 700;

      &[data-variant="GalleryCardButton"] {
        font-size: 2.4rem;
        white-space: nowrap;
        font-weight: 800;
        background-color: transparent;
        color: ${theme.colors.hex.white};
        border-radius: 1rem;
        border: 0.2rem solid;
        border-color: ${theme.colors.hex.primary.color600};
        width: 100%;
        height: 6rem;

        &:hover {
          background-color: ${theme.colors.hex.primary.color600};
          color: ${theme.colors.hex.white};
        }
      }

      &[data-variant="AdmButton"] {
        color: ${theme.colors.hex.gray.color700};
        font-size: 5rem;
        background: none;
        text-transform: none;

        &:hover {
          color: ${theme.colors.hex.black.color900};
          font-weight: bold;
        }
      }

      &[data-variant="institutional"] {
        width: 37.8rem;
        font-weight: 800;
        font-size: 2.4rem;

        &[data-color="banner"] {
          background-color: ${theme.colors.hex.black.color900};
          color: ${theme.colors.hex.white};
        }
      }

      &[data-variant="institutionalHero"] {
        width: 37.8rem;
        font-weight: 1000;
        font-size: 2.4rem;

        &[data-color="hero"] {
          background-color: ${theme.colors.hex.primary.color600};
          color: ${theme.colors.hex.white};
        }
      }
    }
  `}
`;
