import React from "react";
import {
  Card,
  Title,
  SubTitle,
  Content,
  StyledLink,
  ImageCommunity,
} from "./styles";

interface CommunityActionsCardProps {
  img: string;
  title: string;
  subTitle: string;
  link: string;
}

const CommunityActionsCard: React.FC<CommunityActionsCardProps> = ({
  img,
  title,
  subTitle,
  link,
}) => {
  return (
    <Card>
      <ImageCommunity src={img} alt="logo" />
      <Content>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <StyledLink to={link}>Ver mais</StyledLink>
      </Content>
    </Card>
  );
};

export default CommunityActionsCard;
