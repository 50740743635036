import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Home } from "./pages/Home/";
// import { PetsFound } from "./pages/PetsFound";
// import { AdmPetsFound } from "./pages/AdmPetsFound";
import { Adoption } from "./pages/Adoption";
import { Institutional } from "./pages/Institutional";
// import { Login } from "./pages/Login";
import { PublicRoute } from "./components/PublicRoute";
import { PrivateRoute } from "./components/PrivateRoute";
import { useSelector } from "react-redux";
import { userSelector } from "../domain/ducks/authReducer";
import { Construction } from "./pages/Construction";

export const ROUTES = {
  ROOT: "/",
  ADOPTION: "/adocao",
  INSTITUTIONAL: "/institucional",
  CONSTRUCTION: "/construcao",
  // LOGIN: "/login",
  // PetsFound: "/petsfound",
  // AdmPetsFound: "/admpetsfound",
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
};

export const Router: React.FC = () => {
  const { user } = useSelector(userSelector);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={ROUTES.ROOT} element={<PublicRoute />}>
          <Route path={ROUTES.ROOT} element={<Home />} />
          <Route path={ROUTES.ADOPTION} element={<Adoption />} />
          <Route path={ROUTES.INSTITUTIONAL} element={<Institutional />} />
          {/* <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.PetsFound} element={<PetsFound />} />
          <Route path={ROUTES.AdmPetsFound} element={<AdmPetsFound />} /> */}
          <Route path={ROUTES.CONSTRUCTION} element={<Construction />} />
        </Route>
        <Route
          path={ROUTES.ROOT}
          element={<PrivateRoute isAllowed={user.role === "admin"} />}
        >
          {/* Here goes the private routes */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
