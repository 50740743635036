import styled, { css } from "styled-components";

export const ScrollButtonWrapper = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
    border-radius: 50%;
    background-color: ${theme.colors.hex.white};
  `}
`;
