import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Card = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 41rem;
    height: 55.7rem;
    border-radius: 1rem;
    background-color: ${theme.colors.hex.black.color900};
  `}
  > img {
    width: 100%;
    object-fit: cover;
  }
`;

export const Title = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    font-family: ${theme.fonts.inter};
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  `}
`;

export const SubTitle = styled.p`
  ${({ theme }) => css`
    width: 30.5915rem;
    height: 6.6rem;
    color: ${theme.colors.hex.white};
    margin: 2rem 0;
    font-family: ${theme.fonts.inter};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `}
`;

export const Content = styled.div`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  width: 41rem;
  height: 55.7rem;
  flex-shrink: 0;
`;

export const StyledLink = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color800};
    font-family: ${theme.fonts.inter};
    font-size: 2.4rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-align: end;
    margin-top: 5rem;
    margin-right: 2.7rem;
  `}
`;

export const ImageCommunity = styled.img`
  border-radius: 1rem 1rem 0 0;
  box-shadow: 0rem 0.25rem 0.25rem 0rem;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
  `}
`;
