import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-size: cover;
  padding: 13rem 0 17.4rem 0;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
  `}
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standard};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
    margin-bottom: 3rem;
  `}
`;

export const Description = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standard};
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 6rem;
    width: 111.2rem;
  `}
`;

export const CardContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${theme.colors.hex.black.color900};
  `}
`;
