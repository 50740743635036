import styled, { css } from "styled-components";

export const Container = styled.header`
  ${({ theme }) => css`
    width: 100%;
    height: 10rem;
    background: ${theme.colors.hex.black.color900};
    display: flex;
    justify-content: center;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const NavContainer = styled.div`
  display: flex;
  gap: 3rem;
`;
