const hex = {
  primary: {
    color900: "#8b0660",
    color800: "#b10769",
    color700: "#c7076f",
    color600: "#DE0574",
    color500: "#f00379",
    color400: "#f03c8f",
    color300: "#f162a5",
    color200: "#f390bf",
    color100: "#f7bcd9",
    color50: "#fbe4ef",
  },

  gray: {
    color900: "#2F2F2F",
    color800: "#515151",
    color700: "#717171",
    color600: "#868686",
    color500: "#b1b1b1",
    color400: "#cecece",
    color300: "#eeeeee",
    color200: "#f3f3f3",
    color100: "#f8f8f8",
    color50: "#fdfdfd",
  },
  red: {
    color900: "#ba000f",
    color800: "#c8131d",
    color700: "#d51e24",
    color600: "#e72a2a",
    color500: "#f6362a",
    color400: "#f14947",
    color300: "#e86d6c",
    color200: "#f19695",
    color100: "#ffcbd0",
    color50: "#ffeaed",
  },
  green: {
    color900: "#00631f",
    color800: "#008334",
    color700: "#00943f",
    color600: "#00a74c",
    color500: "#00b656",
    color400: "#00c36e",
    color300: "#2dce88",
    color200: "#7edcab",
    color100: "#b4eacc",
    color50: "#e0f7ea",
  },
  blue: {
    color900: "#0057b3",
    color800: "#0078d5",
    color700: "#0089ea",
    color600: "#009dff",
    color500: "#00abff",
    color400: "#00b9ff",
    color300: "#3ec6ff",
    color200: "#7dd6ff",
    color100: "#b2e6ff",
    color50: "#e1f5ff",
  },

  black: {
    color900: "#000000",
    color800: "#262626",
    color700: "#434343",
    color600: "#555555",
    color500: "#7b7b7b",
    color400: "#9d9d9d",
    color300: "#c4c4c4",
    color200: "#d9d9d9",
    color100: "#e9e9e9",
    color50: "#f5f5f5",
    blur700: "#00000099",
  },

  white: "#FFFFFF",
  purpleBlue: "#5e72e4",
  lightPurple: "#32325d",
};

const rgba = {
  black: {
    semiTrasnparent: "rgba(0, 0, 0, 0.20)",
    lightTrasnparent: "rgba(12, 0, 0, 0.80)",
  },
};

const gradient = {};

export const colors = {
  hex,
  rgba,
  gradient,
};
