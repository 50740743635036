import {
  ActionReducerMapBuilder,
  createReducer,
  PayloadAction,
  Reducer,
} from "@reduxjs/toolkit";
import { EngageState } from "../DomainLayer";
import {
  getAllPetsThunk,
  getPetThunk,
  createPetThunk,
} from "../thunks/petThunk";
import { PetInfo } from "../entities/Pet";

export type PetActionType = PayloadAction<any>;

export interface PetState {
  pets: PetInfo[];
  pet: PetInfo;
  isLoading: boolean;
}

export const PET_INITIAL_STATE: PetState = {
  pets: [],
  pet: {} as PetInfo,
  isLoading: false,
};

export const petSelector = (state: EngageState): PetState => state.pet;

function handlePendingThunk(state: PetState): PetState {
  return {
    ...state,
    isLoading: true,
  };
}

function handleRejectedThunk(state: PetState): PetState {
  return {
    ...state,
    isLoading: false,
  };
}

function handleGetAllPetsThunk(
  state: PetState,
  action: PayloadAction<PetInfo[]>
): PetState {
  return {
    ...state,
    pets: action.payload,
    isLoading: false,
  };
}

function handleGetPetThunk(
  state: PetState,
  action: PayloadAction<PetInfo>
): PetState {
  return {
    ...state,
    pet: action.payload,
    isLoading: false,
  };
}

const builder = (builder: ActionReducerMapBuilder<PetState>) =>
  builder
    .addCase(getAllPetsThunk.pending.type, handlePendingThunk)
    .addCase(getAllPetsThunk.rejected.type, handleRejectedThunk)
    .addCase(getAllPetsThunk.fulfilled.type, handleGetAllPetsThunk)
    .addCase(getPetThunk.pending.type, handlePendingThunk)
    .addCase(getPetThunk.rejected.type, handleRejectedThunk)
    .addCase(getPetThunk.fulfilled.type, handleGetPetThunk)
    .addCase(createPetThunk.pending.type, handlePendingThunk)
    .addCase(createPetThunk.rejected.type, handleRejectedThunk);

export const petReducer: Reducer<PetState, PetActionType> = createReducer(
  PET_INITIAL_STATE,
  builder
);
