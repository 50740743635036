import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  background-size: cover;
  padding: 16.5rem 0;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
  `}
`;
export const TitleWayToHelp = styled.h1`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;

    color: ${theme.colors.hex.black.color900};

    font-family: ${theme.fonts.standard};
    font-size: 6rem;

    padding-bottom: 5.9rem;
  `}
`;

export const DivWaytoHelp = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    display: flex;
    flex-direction: row;
    gap: 7.7rem;
  `}
`;
