import React, { useState } from "react";
import {
  FooterContainer,
  Wrapper,
  BoxFlexColumn,
  StyledLogo,
  InfoContainer,
  Subtitle,
  EmailContainer,
  LinkEmail,
  LinkContainer,
  CopyrightMessage,
  SocialIconContainer,
  StyledLink,
  StyledLinkButton,
} from "./styles";
import { ReactComponent as IconFacebook } from "../../../assets/img/social/IconFacebook.svg";
import { ReactComponent as IconInstagram } from "../../../assets/img/social/IconInstagram.svg";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { ROUTES } from "../../../Router";
import { Modal } from "../../organisms/Modal";

export const Footer: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <FooterContainer>
      <Wrapper>
        <BoxFlexColumn>
          <StyledLogo to={ROUTES.ROOT}>
            <Logo />
          </StyledLogo>
          <InfoContainer>
            <Subtitle>Contato</Subtitle>
            <EmailContainer>
              <LinkEmail
                href="mailto:contato@solpra.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                contato@solpra.com.br
              </LinkEmail>
            </EmailContainer>
            <SocialIconContainer>
              <a
                href="https://www.facebook.com/profile.php?id=100064759477109"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconFacebook />
              </a>
              <a
                href="https://www.instagram.com/solpravoluntariado/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconInstagram />
              </a>
            </SocialIconContainer>
          </InfoContainer>
          <InfoContainer>
            <Subtitle>Links Rápidos</Subtitle>
            <LinkContainer>
              <StyledLink to={ROUTES.ROOT}>Home</StyledLink>
              <StyledLink to={ROUTES.INSTITUTIONAL}>Institucional</StyledLink>
              <StyledLink to={ROUTES.ADOPTION}>Adoção</StyledLink>
              <StyledLink to={ROUTES.CONSTRUCTION}>Blog</StyledLink>
              <StyledLinkButton onClick={handleOpenModal}>
                Doar
              </StyledLinkButton>
              <StyledLink to={ROUTES.CONSTRUCTION}>Login</StyledLink>
            </LinkContainer>
          </InfoContainer>
        </BoxFlexColumn>
        <CopyrightMessage>Solpra. Alguns direitos reservados.</CopyrightMessage>
      </Wrapper>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        isQRCodeModal={true}
      />
    </FooterContainer>
  );
};
