import { Outlet } from "react-router";
import { Footer } from "./molecules/Footer";
import { Header } from "./molecules/Header";
import { Navigate } from "react-router";

interface PrivateRouteProps {
    isAllowed: boolean;
    redirectTo?: string;
  }
  
  // eslint-disable-next-line react/prop-types
  export const PrivateRoute: React.FC<PrivateRouteProps> = ({ isAllowed, redirectTo = "/"}) => {
    if (!isAllowed) {
      return <Navigate to={redirectTo} />;
    }
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
