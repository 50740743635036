import axios from "axios";
import { PetInfo } from "../../domain/entities/Pet";

export async function getAllPets(): Promise<PetInfo[]> {
  return (await axios.get("/pets")).data;
}

export async function getPet(id: number): Promise<PetInfo> {
  return (await axios.get(`/pets/${id}`)).data;
}

export async function createPet(data: PetInfo): Promise<void> {
  const { id, ...body } = data;
  await axios.post(`/pets/`, body);
}

export async function updatePet(data: PetInfo): Promise<void> {
  const { id, ...body } = data;
  await axios.put(`/pets/${id}`, body);
}

export async function deletePet(id: number) {
  await axios.delete(`/pets/${id}`);
}
