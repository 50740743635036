import React, { AllHTMLAttributes, ReactNode } from 'react'
import { Container, Wrapper } from './styles'

interface BannerProps extends AllHTMLAttributes<HTMLDivElement> {
  backgroundImage?: string
  backgroundColor?: string
  children: ReactNode
}

export const Banner: React.FC<BannerProps> = ({
  backgroundImage,
  backgroundColor,
  children
}: BannerProps) => {
  return (
    <Container style={{ backgroundImage: `url(${backgroundImage})`, backgroundColor }}>
      <Wrapper>{children}</Wrapper>
    </Container>
  )
}
