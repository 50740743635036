import { Button } from "../../components/atoms/Button";
import { Banner } from "../../components/molecules/Banner";
import { InstitutionalPageHero } from "../../components/molecules/InstitutionalPageHero";
import { TeamCarousel } from "../../components/molecules/TeamCarousel";
import backgroundImage from "../../assets/img/heroImage.png";
// import institucionalBannerImg1 from "../../assets/img/institucional-banner-img1.png";
import {
  Title,
  HeroTitle,
  Text,
  HeroText,
  BannerTitleWorks,
  TitleColored,
  BannerParagraphWorks,
  ParagraphColored,
  BannerParagraphPart,
  BannerTitlePart,
  BannerTitleVolunteers,
  BannerContainer,
  BannerParagraphVolunteers,
  ParagraphColoredVolunteers,
  // BannerImageVolunteers,
  BannerTitleMission,
} from "./styles";
import { Link } from "react-router-dom";
import { ROUTES } from "../../Router";
import { ScrollButton } from "../../components/atoms/ScrollButton";

export const Institutional = () => {
  return (
    <>
      <InstitutionalPageHero backgroundImage={backgroundImage}>
        <HeroTitle>Nossa História e Missão.</HeroTitle>
        <HeroText>
          Na SOLPRA, nossa paixão pela proteção animal moldou nossa jornada.
          Nossa história remonta a [ano de fundação da ONG], quando um grupo de
          amantes de animais se uniu com um objetivo comum: fazer a diferença na
          vida dos animais abandonados e maltratados. Desde então, temos
          dedicado nossos esforços incansavelmente para promover o bem-estar
          animal e facilitar adoções responsáveis.
        </HeroText>
        <Link to={ROUTES.CONSTRUCTION}>
          <Button variant="contained" color="hero">
            saiba mais a seguir
          </Button>
        </Link>
      </InstitutionalPageHero>

      <Banner backgroundColor="white">
        <BannerTitleMission>
          Nossa Missão: Compromisso com o Bem-Estar Animal
        </BannerTitleMission>
        <BannerParagraphPart>
          Nossa missão é clara: resgatar, proteger e promover o amor pelos
          animais. Nós nos dedicamos incansavelmente a oferecer cuidados, abrigo
          e, acima de tudo, esperança aos animais abandonados e maltratados.
          Acreditamos que cada ser vivo merece uma vida digna e cheia de amor, e
          trabalhamos diariamente para realizar essa visão, principalmente
          através do nosso Programa Adote.
        </BannerParagraphPart>
        <Link to={ROUTES.CONSTRUCTION}>
          <Button variant="contained" color="banner">
            Ver no Blog
          </Button>
        </Link>
      </Banner>

      <Banner backgroundColor="black">
        <BannerTitleWorks>
          Como Funciona o Programa <TitleColored>Adote</TitleColored>
        </BannerTitleWorks>
        <BannerParagraphWorks>
          Nossa missão é clara: resgatar, proteger e promover o amor pelos
          animais. Nós nos dedicamos incansavelmente a oferecer cuidados, abrigo
          e, acima de tudo, esperança aos animais abandonados e maltratados.
          Acreditamos que cada ser vivo merece uma vida digna e cheia de amor, e
          trabalhamos diariamente para realizar essa visão, principalmente
          através do nosso
          <Link to={ROUTES.CONSTRUCTION}>
            <ParagraphColored> Programa Adote</ParagraphColored>
          </Link>
          .
        </BannerParagraphWorks>
      </Banner>

      <Banner backgroundColor="white">
        <BannerTitlePart>Achados e Perdidos</BannerTitlePart>
        <BannerParagraphPart>
          Você também pode fazer parte da nossa missão. Há várias maneiras de se
          envolver: faça uma doação, seja voluntário, adote um animal
          necessitado ou participe das nossas campanhas de conscientização. Sua
          ajuda faz a diferença.
        </BannerParagraphPart>
        <Link to={ROUTES.ADOPTION}>
          <Button variant="contained" color="banner">
            Encontre seu PET
          </Button>
        </Link>
      </Banner>

      <Banner backgroundColor="black">
        <BannerTitleVolunteers>Nosso Time de Voluntários</BannerTitleVolunteers>
        <BannerContainer>
          <BannerParagraphVolunteers>
            Nossa equipe é formada por um grupo diversificado de{" "}
            <ParagraphColoredVolunteers>voluntários</ParagraphColoredVolunteers>{" "}
            apaixonados. Unidos pelo amor aos animais, eles dedicam tempo,
            esforço e carinho para cuidar dos nossos resgatados e promover a
            adoção responsável.
          </BannerParagraphVolunteers>
          {/* <BannerImageVolunteers
            src={institucionalBannerImg1}
            alt="Voluntários"
          /> */}
        </BannerContainer>
      </Banner>

      <Banner>
        <Title>Nosso Time Técnico: Transformando Ideias em Ação</Title>
        <Text>
          Além dos voluntários, nossa equipe técnica é composta por
          desenvolvedores, programadores, UX/UI designers e outros profissionais
          talentosos que trabalham para garantir que nossa mensagem chegue ao
          maior número de pessoas possível. Eles são os arquitetos do nosso
          site, aplicativos e campanhas online.
        </Text>
        <TeamCarousel />
      </Banner>
      <ScrollButton />
    </>
  );
};
