import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.breakpoint.maxWidth};
    width: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.hex.black.color900};
    width: 87rem;
    height: 49.2rem;
    display: flex;
    flex-shrink: 0;
    border-radius: 1rem;
    align-items: center;
    flex-direction: row;
  `}
`;

export const ImgContainer = styled.div`
  display: flex;
  border-radius: 1rem 0 0 1rem;
  overflow: hidden;
  width: 37.9rem;
  height: 49.2rem;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 5.6rem;
  margin-right: 6.6rem;
`;

export const DescriptionContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standard};
    font-size: 2.8rem;
    font-style: italic;
    font-weight: 300;
    line-height: 3.6rem;
  `}
`;

export const NameContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color800};
    font-family: ${theme.fonts.standard};
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.6rem;
    margin-top: 3rem;
    margin-left: auto;
  `}
`;

export const PositionContainer = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    font-family: ${theme.fonts.standard};
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.6rem;
    margin-left: auto;
    white-space: nowrap;
  `}
`;

export const TeamSwiper = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.breakpoint.maxWidth};
    position: relative;
    margin: auto;

    .swiper-button-next,
    .swiper-button-prev {
      ${({ theme }) => css`
        color: ${theme.colors.hex.primary.color600};
        flex-shrink: 0;
      `}
    }
  `}
`;

export const ButtonTeam = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    left: -4rem;
    right: -4rem;
    z-index: 2;

    > button {
      color: ${theme.colors.hex.primary.color600};
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      > svg path {
        stroke: ${theme.colors.hex.primary.color600};
      }

      &:disabled {
        opacity: 0.3;
      }
    }
  `}
`;
