import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 96rem;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    --swiper-pagination-bullet-size: 2.2rem;
    --swiper-pagination-bullet-width: 2.2rem;
    --swiper-pagination-bullet-height: 2.2rem;
    --swiper-pagination-bullet-horizontal-gap: 1.7rem;
    --swiper-pagination-bullet-horizontal-gap: 1.7rem;
    --swiper-pagination-margin-bottom: 2.8rem;

    &[data-color="primary-swiper"] {
      --swiper-pagination-color: ${theme.colors.hex.primary.color600};
      --swiper-pagination-bullet-inactive-color: ${theme.colors.hex.gray
        .color20};
    }

    &[data-color="secondary-swiper"] {
      --swiper-pagination-color: ${theme.colors.hex.white};
      --swiper-pagination-bullet-inactive-color: ${theme.colors.hex.gray
        .color20};
    }
  `}
`;

export const StyledSwiper = styled.div`
  position: relative;

  .swiper-pagination {
    margin-bottom: 2.9rem;
  }
`;
export const ButtonContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    padding-inline: 8.35rem;
    z-index: 2;

    > button {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    > button:hover svg path {
      stroke: ${theme.colors.hex.primary.color600};
    }
  `}
`;
