import { Outlet } from "react-router";
import { Footer } from "./molecules/Footer";
import { Header } from "./molecules/Header";

export const PublicRoute = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
