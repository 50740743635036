import React, { ReactNode } from 'react'
import { Hero, Wrapper } from './styles'

interface InstitutionalPageHeroProps {
  backgroundImage: string
  children?: ReactNode
}

export const InstitutionalPageHero: React.FC<InstitutionalPageHeroProps> = ({
  backgroundImage,
  children
}) => {
  return (
    <Hero style={{ backgroundImage: `url(${backgroundImage})` }}>
      <Wrapper>{children}</Wrapper>
    </Hero>
  )
}
