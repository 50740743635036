import {
  ConstructionWrapper,
  ConstructionTitle,
  ConstructionImage,
} from "./styles";
import constructionImage from "../../assets/img/ConstructionPage.png";

export const Construction = () => {
  return (
    <ConstructionWrapper>
      <ConstructionImage src={constructionImage} />
      <ConstructionTitle>Página em Construção</ConstructionTitle>
    </ConstructionWrapper>
  );
};
