import styled, { css } from "styled-components";

export const StyledTitle = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 3.8rem;
    margin-bottom: 1.2rem;
  `}
`;

export const StyledDescription = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.lato};
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `}
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: ${theme.breakpoint.maxWidth};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 14.9rem;
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    width: 37.9rem;
    height: 73rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;

export const CardConteiner = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    display: flex;
    flex-direction: column;
  `}
  > img {
    width: 100%;
    height: 48.3rem;
    object-fit: cover;
    border-radius: 1rem;
    box-shadow: 0rem 0.5rem 0.5rem 0rem rgba(0, 0, 0, 0.25);
  }
`;
