import styled, { css } from "styled-components";
import { theme } from "../../../theme";

export const ModalOverlay = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${theme.colors.rgba.black.lightTrasnparent};
  z-index: 999;
`;

export const ModalContainer = styled.div`
  max-height: 66rem;
  max-width: 52rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.8rem;
  border: 0.1rem solid #000;
  overflow: hidden;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  min-height: 3rem;
  padding: 1rem 3rem;
  background-color: ${theme.colors.hex.white};
  text-align: center;
`;

export const ModalBody = styled.div`
  max-height: 60rem;
  padding: 1rem 3rem;
  flex-grow: 1;
  background-color: ${theme.colors.hex.white};
`;

export const ModalFooter = styled.div`
  min-height: 3rem;
  padding: 1rem 3rem;
  background-color: ${theme.colors.hex.white};
  text-align: center;
`;

export const QRCodeModalHeader = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    font-size: 2.4rem;
  `}
`;

export const QRCodeModalBody = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.hex.red.color900};
  `}
`;

export const QRCodeModalFooter = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
  `}
`;
