import styled, { css, keyframes } from "styled-components";

export const ConstructionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 20rem);
`;

export const ConstructionTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    font-size: 4rem;
  `}
`;

const moveUpAndDown = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ConstructionImage = styled.img`
  width: 800px;
  height: auto;
  animation: ${moveUpAndDown} 4s infinite;
`;
