import styled, { css } from "styled-components";

export const HeroTitle = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color200};
    font-family: ${theme.fonts.standard};
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
    max-width: 63.2rem;
  `}
`;

export const HeroText = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.gray.color200};
    font-family: ${theme.fonts.standard};
    margin-top: 3rem;
    margin-bottom: 6rem;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.6rem;
    max-width: 61rem;
  `}
`;
export const BannerTitleMission = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 102.4rem;
  `}
`;

export const BannerTitleWorks = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 122.6rem;
  `}
`;

export const TitleColored = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
  `}
`;

export const BannerParagraphWorks = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.lato};
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 13rem;
    width: 129rem;
  `}
`;

export const ParagraphColored = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
    text-decoration-line: underline;
  `}
`;

export const BannerTitlePart = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 102.4rem;
  `}
`;

export const BannerParagraphPart = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.lato};
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 11rem;
    width: 129rem;
  `}
`;

export const BannerTitleVolunteers = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.standart};
    font-size: 6rem;
    font-weight: 1000;
    text-transform: uppercase;
    width: 122.6rem;
  `}
`;

export const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BannerParagraphVolunteers = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.white};
    font-family: ${theme.fonts.lato};
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 13rem;
    width: 45.5rem;
  `}
`;

export const ParagraphColoredVolunteers = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.hex.primary.color600};
  `}
`;

export const BannerImageVolunteers = styled.img`
  width: 76.6rem;
  height: 44.9rem;
  border-radius: 1rem;
  margin-top: 10.1rem;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standard};
    display: inline;
    font-size: 6rem;
    font-style: normal;
    font-weight: 1000;
    line-height: normal;
    text-transform: uppercase;
  `}
`;

export const Text = styled.p`
  ${({ theme }) => css`
    color: ${theme.colors.hex.black.color900};
    font-family: ${theme.fonts.standard};
    margin-top: 2rem;
    margin-bottom: 13rem;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3.6rem;
  `}
`;
