import React from "react";
import {
  StyledTitle,
  StyledDescription,
  Container,
  CardConteiner,
} from "./styles";
import { Button } from "../../atoms/Button";
import { Wrapper } from "../Banner/styles";

interface CardProps {
  imageSrc?: string;
  altText: string;
  title: string;
  description: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const Card: React.FC<CardProps> = ({
  imageSrc,
  altText,
  title,
  description,
  buttonText,
  onButtonClick,
}) => {
  return (
    <Wrapper>
      <Container>
        <CardConteiner>
          <img src={imageSrc} alt={altText} />
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>{description}</StyledDescription>
        </CardConteiner>
        <Button onClick={onButtonClick} variant="contained" color="banner">
          {buttonText}
        </Button>
      </Container>
    </Wrapper>
  );
};
